import { UseQueryOptions, useQuery } from "@tanstack/react-query"

import { AvatarPreviewResponse } from "@spatialsys/js/sapi/sapi/avatars"

import { useSapi } from "../use-sapi"

export const GET_AVATAR_PREVIEW_QUERY_KEY = "avatarSdkGetAvatarPreview"

/**
 * Fetch the preview image from avatar SDK for a given avatar ID
 * This query will only be called when `token` and `avatarId` are both defined.
 */
export const useGetAvatarPreviewQuery = (
  previewUrl?: string,
  options?: UseQueryOptions<AvatarPreviewResponse, unknown, AvatarPreviewResponse, (string | undefined)[]>
) => {
  const sapiClient = useSapi()
  return useQuery({
    queryKey: [GET_AVATAR_PREVIEW_QUERY_KEY, previewUrl],
    queryFn: () => sapiClient.avatars.getAvatarPreview(previewUrl as string),
    // Only run the query when avatarID is defined
    enabled: Boolean(previewUrl),
    staleTime: Infinity,
    // The preview for a given avatarId will never change.
    // There is no way to update an avatar's image after it has been created
    ...options,
  })
}
