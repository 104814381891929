import Image from "next/image"
import Link from "next/link"
import { memo } from "react"
import { Balancer } from "react-wrap-balancer"

import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import Config from "@spatialsys/web/config"
import { PlatformFeatures } from "@spatialsys/web/core/js/components/marketing-section/platform-features"
import { spatialCdnImageLoader } from "@spatialsys/web/core/js/util/next-image-loader"
import { Button, Container, Heading } from "@spatialsys/web/ui"

export const BuildOnSpatial = memo(function BuildOnSpatial() {
  return (
    <TrackedComponent id={TrackedComponents.WhyBuildOnSpatial} as="div" className="relative py-16 sm:py-20">
      <Image
        src={`${Config.PUBLIC_ASSETS_BASE_URL}/brands/game-collage.webp`}
        alt="background image"
        fill
        className="object-cover"
        loader={spatialCdnImageLoader}
        unoptimized
      />
      <div className="absolute inset-0 bg-black/75 backdrop-blur" />

      <div className="relative z-10">
        <Container className="grid max-w-[1080px] justify-items-center gap-6 px-4 md:gap-8 lg:gap-10">
          <Balancer>
            <Heading className="text-center text-m3 lg:text-m2" weight="black">
              Why Top Creators Build on Spatial
            </Heading>
          </Balancer>
          <PlatformFeatures />
          <Button
            as={Link}
            href="https://toolkit.spatial.io"
            target="_blank"
            rel="noreferrer"
            prefetch={false}
            color="white"
            size="md"
            className="mt-2 w-fit sm:mt-0"
          >
            Get Started
          </Button>
        </Container>
      </div>
    </TrackedComponent>
  )
})
