import { AxiosInstance } from "axios"

import { getFirstSapiError } from "../utils/sapi-error"

/** From register user */
export type SamlSsoResponseWithUserId = {
  userId: string
}

export function createSamlSsoEndpoint(client: AxiosInstance) {
  return {
    /** Attempts to register user with SAML SSO email after successful login */
    register: async function (): Promise<SamlSsoResponseWithUserId> {
      try {
        const response = await client.post<SamlSsoResponseWithUserId>(``)
        return response.data
      } catch (error) {
        throw getFirstSapiError(error) ?? error
      }
    },
  }
}

export type PlacebSsoRequest = {
  userKey: string
}

export type PlacebSsoResponse = {
  authToken: string
  userId: string
}

export function createPlacebSsoEndpoint(client: AxiosInstance) {
  return {
    authenticate: async function (req: PlacebSsoRequest): Promise<PlacebSsoResponse> {
      try {
        const response = await client.post<PlacebSsoResponse>(`/placeb/auth`, req)
        return response.data
      } catch (error) {
        throw getFirstSapiError(error) ?? error
      }
    },
  }
}
