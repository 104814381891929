import { memo, useCallback } from "react"
import Balancer from "react-wrap-balancer"

import { useAppContext } from "@spatialsys/web/app-context"
import Config from "@spatialsys/web/config"
import { Button, Heading, Text, cn } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"

type CrossPlatformProps = {
  isSmallScreen: boolean
}

export const CrossPlatform = memo(function CrossPlatform({ isSmallScreen }: CrossPlatformProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"
  const actions = useAppContext((context) => context.actions)
  const showOldHomepage = useCallback(() => {
    actions.showOldHomepage()
  }, [actions])

  return (
    <div className={cn("grid gap-8", isSmallScreen ? "py-[48px]" : "py-[84px]")}>
      <SectionHeader
        title={isKorean ? "크로스 플랫폼 지원" : "Cross-Platform"}
        titleClassName={isKorean ? "font-kr font-bold" : undefined}
        catchphrase={
          isKorean
            ? "단 한번의 클릭으로 당신만의 세상을 웹, 모바일, XR로 공유하세요"
            : "Publish Your Experience to Web, Mobile & XR"
        }
        catchphraseClassName={isKorean ? "font-kr" : undefined}
        description={
          isKorean
            ? "스페이셜 월드는 크로스 플랫폼을 지원하여 어디서나, 어떤 기기로든 접속할 수 있습니다."
            : "Access Spatial worlds from any device, anywhere."
        }
        descriptionClassName={isKorean ? "font-kr" : undefined}
      />
      <Button
        size={isSmallScreen ? "lg" : "xl"}
        className={cn("mx-auto w-full bg-popover text-sm sm:max-w-[480px] sm:text-lg", isKorean && "font-kr font-bold")}
        noShadow
        noScaleOnHover={isSmallScreen}
        onClick={showOldHomepage}
      >
        {isKorean ? "시작하기" : "Get started"}
      </Button>
      <div
        className={cn(
          "mx-auto grid w-full grid-cols-3 justify-between gap-20 sm:w-[80%]",
          isSmallScreen && "grid-cols-1"
        )}
      >
        <div>
          <div className="flex justify-center">
            <img
              src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/cross-platform/web.webp`}
              alt="Web"
              className="w-[80%] object-contain sm:w-full"
              loading="lazy"
            />
          </div>
          <Heading size={isSmallScreen ? "h4" : "h3"} className={isKorean ? "font-kr font-bold" : undefined}>
            {isKorean ? "웹" : "Web"}
          </Heading>
          <Text className={cn("mt-2 text-sm text-muted-foreground sm:text-lg", isKorean && "font-kr")}>
            {isKorean ? (
              <>
                복잡한 다운로드 없이 웹페이지만으로도 몰입형 경험을 <Balancer>시작할 수 있습니다.</Balancer>
              </>
            ) : (
              "Enjoy immersive experiences as webpages on your PC, no download required."
            )}
          </Text>
        </div>
        <div>
          <div className="flex justify-center">
            <img
              src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/cross-platform/vr.webp`}
              alt="VR"
              className="w-[80%] object-contain sm:w-full"
              loading="lazy"
            />
          </div>
          <Heading size={isSmallScreen ? "h4" : "h3"} className={isKorean ? "font-kr font-bold" : undefined}>
            VR
          </Heading>
          <Text className={cn("mt-2 text-sm text-muted-foreground sm:text-lg", isKorean && "font-kr")}>
            {isKorean
              ? "고화질의 스페이셜 월드에서 깊이 있는 몰입과 의미 있는 개별 경험을 시작하세요."
              : "Deep immersion and meaningful personal connections through Spatial worlds."}
          </Text>
        </div>
        <div>
          <div className="flex justify-center">
            <img
              src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/cross-platform/mobile.webp`}
              alt="Mobile"
              className="w-[80%] object-contain sm:w-full"
              loading="lazy"
            />
          </div>
          <Heading size={isSmallScreen ? "h4" : "h3"} className={isKorean ? "font-kr font-bold" : undefined}>
            {isKorean ? "모바일" : "Mobile"}
          </Heading>
          <Text className={cn("mt-2 text-sm text-muted-foreground sm:text-lg", isKorean && "font-kr")}>
            {isKorean ? (
              <>
                모바일로 언제든, 어디서든 가상 세상을 탐험하고 <Balancer>사람들과 소통하세요.</Balancer>
              </>
            ) : (
              "Explore virtual experiences and connect with others from anywhere, anytime."
            )}
          </Text>
        </div>
      </div>
    </div>
  )
})
