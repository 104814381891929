import type { ImageLoaderProps } from "next/image"

// Match any of our CDN domains:
// - cdn.staging.spatial.io
// - cdn2.spatial.io
// - cdn-b.spatial.io
const cdnPattern = /^https?:\/\/(?:cdn(?:2|-b)?\.(?:staging\.)?spatial\.io)/

/**
 * Image loader for Next.js Image component for an image served by the Spatial CDN.
 * The image optimization is done by the Spatial CDN instead of Next.js.
 * This function should be used as the `loader` prop for the Next.js Image component for
 * any image served by the Spatial CDN that needs to be optimized.
 *
 * In the future, this loader can be set globally in next.config.js, if we use the Spatial CDN
 * for all static assets.
 *
 * Example transformation: `https://cdn2.staging.spatial.io/assets/v1/thumbnails/6556b9927345609989ae758f/customThumbnail/r/c84616ac3762934d4393fa9028238e163b6a9255c82d9884fa150bfc3790a374/1721944577/w384q75fwebp`
 *
 * @see https://nextjs.org/docs/app/api-reference/components/image#loader
 * @see https://nextjs.org/docs/app/api-reference/components/image#loaderfile
 */
export function spatialCdnImageLoader({ src, width, quality = 75 }: ImageLoaderProps) {
  if (cdnPattern.test(src)) {
    return `${src}/w${width}q${quality}fwebp`
  }

  // Return original source for non-CDN or non-optimizable images (svg, gif etc)
  return src
}
