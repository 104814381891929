import axios, { AxiosInstance } from "axios"

import { LogChannel } from "@spatialsys/js/logger"
import { ClientVersionChannel } from "@spatialsys/js/types"
import { SPATIAL_CLIENT_IP_HEADER_NAME, SPATIAL_UID_HEADER_NAME } from "@spatialsys/js/util/constants"

import { createAnalyticsEndpoint } from "./analytics"
import { createAuthEndpoints } from "./auth"
import { createSamlSsoLoginEndpoint } from "./auth/saml-sso"
import { createBadgesEndpoint } from "./badges"
import { createCategoriesEndpoint } from "./categories"
import { createCheckoutEndpoint } from "./checkout"
import { createContentEndpoints } from "./content"
import { createContentV2Endpoints } from "./content-v2"
import { createExploreEndpoints } from "./explore"
import { createExternalGamesEndpoint } from "./external-games"
import { createFeatureFlagEndpoints } from "./feature-flags"
import { createFriendsEndpoint } from "./friends"
import { createItemsEndpoint } from "./items"
import { createNotificationEndpoints } from "./notifications/tokens"
import { createPackagesEndpoints } from "./packages"
import { createPayoutsEndpoint } from "./payouts"
import { createProfilesEndpoint } from "./profiles"
import { createCoinsEndpoint } from "./purchase/coins"
import { createSpaceSubscriptionEndpoints } from "./purchase/space-subscription"
import { createStripeEndpoint } from "./purchase/stripe"
import { createV2Endpoints } from "./sapi-v2"
import { createMenuEndpoints } from "./sapi-v2/menu/menu"
import { createAvatarEndpoints } from "./sapi/avatars"
import { createBillingEndpoints } from "./sapi/billing"
import { createBootstrapEndpoints } from "./sapi/bootstrap"
import { createContentEndpoints as createContentV1Endpoints } from "./sapi/content"
import { createIntegrationsEndpoints } from "./sapi/integrations"
import { createOrgsEndpoints } from "./sapi/org"
import { createRoomsEndpoints } from "./sapi/rooms"
import { createUsersEndpoints } from "./sapi/users"
import { createSdkEndpoints } from "./sdk"
import { createSpacesV2Endpoints } from "./spaces-v2"
import { createSpaceEndpoints } from "./spaces/space"
import { createSpacesEndpoints } from "./spaces/spaces"
import { createSpaceSubscriptionsEndpoints } from "./subscriptions-v2/space-subscriptions"
import { createAppStoreEndpoints } from "./subscriptions/app-store"
import { PlatformHeaderString } from "./types"
import { createUsersV2Endpoints } from "./users-v2"
import { createUsersServiceEndpoints } from "./users/profiles"
import { SPATIAL_USER_AGENT_HEADER, createSpatialUserAgentHeader } from "./utils/spatial-user-agent-header"
import { createWorldsEndpoint } from "./worlds"

export type SapiClient = ReturnType<typeof createSapiClient>

export const SAPILogChannel = new LogChannel("SAPI")

/**
 * Vercel allows a maximum execution limit of 60s on their "Pro" plan.
 * However, 30s is a more reasonable timeout, requests should not be taking anywhere
 * near this long!
 */
const DEFAULT_TIMEOUT = 30000

// Set a global timeout for axios, since `axios.get` and `axios.post` is used sparsely
// throughout various parts of the codebase still.
axios.defaults.timeout = DEFAULT_TIMEOUT

export type SapiClientParams = {
  apiUrl: string
  authToken?: string
  channelName: ClientVersionChannel
  clientIp?: string
  platform: PlatformHeaderString
  spatialUid?: string
  spatialUnityVersion: string
  /** Maximum request length for a request in ms */
  timeoutMs?: number
}

export function createSapiClient({
  platform,
  spatialUnityVersion,
  channelName,
  clientIp,
  timeoutMs = 30000,
  authToken,
  spatialUid,
  apiUrl,
}: SapiClientParams) {
  let userAgentHeader = createSpatialUserAgentHeader(platform, spatialUnityVersion, channelName)
  let _channelName = channelName

  const headers: Record<string, string> = {
    [SPATIAL_USER_AGENT_HEADER]: userAgentHeader,
  }
  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`
  }
  if (spatialUid) {
    headers[SPATIAL_UID_HEADER_NAME] = spatialUid
  }
  if (clientIp) {
    headers[SPATIAL_CLIENT_IP_HEADER_NAME] = clientIp
  }

  const clients: AxiosInstance[] = []

  function createClient(basePath: string) {
    const client = axios.create({
      withCredentials: true,
      xsrfCookieName: "sapi_token",
      xsrfHeaderName: "sapi_token",
      baseURL: `${apiUrl}${basePath}`,
      headers,
      timeout: timeoutMs,
    })
    clients.push(client)
    return client
  }

  /**
   * Helper function to mutate the AxiosInstances underlying the SapiClient.
   */
  function updateClient(cb: (client: AxiosInstance) => void) {
    for (const client of clients) {
      cb(client)
    }
  }

  const apiV1Client = createClient("/api/v1")

  return {
    auth: createAuthEndpoints(createClient("/auth/v1")),
    avatars: createAvatarEndpoints(apiV1Client),
    badges: createBadgesEndpoint(createClient("/v2/badges")),
    billing: createBillingEndpoints(apiV1Client),
    bootstrap: createBootstrapEndpoints(apiV1Client, _channelName, spatialUnityVersion),
    checkout: createCheckoutEndpoint(createClient("/v2/checkout")),
    content: createContentEndpoints(createClient("/content/v1")),
    contentV1: createContentV1Endpoints(apiV1Client),
    contentV2: createContentV2Endpoints(createClient("/v2/files")),
    externalGames: createExternalGamesEndpoint(createClient("/v2/external-games")),
    explore: createExploreEndpoints(createClient("/v2/explore")),
    featureFlags: createFeatureFlagEndpoints(createClient("/v2/feature-flags")),
    integrations: createIntegrationsEndpoints(apiV1Client),
    items: createItemsEndpoint(createClient("/v2/items")),
    menu: createMenuEndpoints(createClient("/v2/menu")),
    notifications: createNotificationEndpoints(createClient("/notification/v1")),
    orgs: createOrgsEndpoints(apiV1Client),
    payouts: createPayoutsEndpoint(createClient("/v2/payouts")),
    packages: createPackagesEndpoints(createClient("/v2/packages")),
    purchase: (() => {
      const client = createClient("/v2/purchase")
      return {
        coins: createCoinsEndpoint(client),
        stripe: createStripeEndpoint(client),
        spaceSubscription: createSpaceSubscriptionEndpoints(client),
      }
    })(),
    rooms: createRoomsEndpoints(apiV1Client),
    sdk: createSdkEndpoints(createClient("/sdk/v1")),
    spaces: (() => {
      const client = createClient("/space/v1")
      return {
        space: createSpaceEndpoints(client),
        spaces: createSpacesEndpoints(client),
        categories: createCategoriesEndpoint(client),
      }
    })(),
    subscription: (() => {
      const client = createClient("/subscription/v1")
      return {
        appStore: createAppStoreEndpoints(client),
      }
    })(),
    subscriptionsV2: createSpaceSubscriptionsEndpoints(createClient("/v2/subscriptions")),
    users: createUsersEndpoints(apiV1Client),
    usersV1: (() => {
      const client = createClient("/users/v1")
      return {
        users: createUsersServiceEndpoints(client),
      }
    })(),
    usersV2: createUsersV2Endpoints(createClient("/v2/users")),
    profiles: createProfilesEndpoint(createClient("/v2/profiles")),
    v2: createV2Endpoints(createClient("/v2")),
    worlds: createWorldsEndpoint(createClient("/v2/worlds")),
    friends: createFriendsEndpoint(createClient("/v2/friends")),
    spacesV2: createSpacesV2Endpoints(createClient("/v2/spaces")),
    analytics: createAnalyticsEndpoint(createClient("/v2/analytics")),
    samlSso: createSamlSsoLoginEndpoint(createClient("/v2/login")),

    get platform() {
      return platform
    },

    get spatialUnityVersion() {
      return spatialUnityVersion
    },

    get channelName() {
      return _channelName
    },

    setSpatialUserAgentHeader(webGlVersion: string, channelName?: ClientVersionChannel) {
      if (channelName) {
        _channelName = channelName
      }

      userAgentHeader = createSpatialUserAgentHeader(platform, spatialUnityVersion, _channelName, webGlVersion)
      updateClient((client) => {
        client.defaults.headers.common[SPATIAL_USER_AGENT_HEADER] = userAgentHeader
      })
    },

    setAuthHeader(accessToken: string) {
      updateClient((client) => {
        client.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
      })
    },

    setSpatialUidHeader(spatialUid: string) {
      updateClient((client) => {
        client.defaults.headers.common[SPATIAL_UID_HEADER_NAME] = spatialUid
      })
    },

    unsetAuthHeader() {
      updateClient((client) => {
        delete client.defaults.headers.common["Authorization"]
      })
    },
    updateClient,
  }
}
