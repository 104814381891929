"use client"

import * as DialogPrimitive from "@radix-ui/react-dialog"
import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"
import { AnimatePresence, m, type MotionProps } from "framer-motion"
import { Fragment, forwardRef, useContext } from "react"

import { ReactComponent as CloseIcon } from "@spatialsys/assets/icons/lucide/x.svg"

import { BaseComponentProps } from "../types"
import { cn } from "../utils"
import { DialogContext } from "./dialog-context"

export type DialogProps = {
  // Specific DialogContent props
  showCloseButton?: boolean
  closeButtonClassName?: string
} & DialogRootProps &
  Pick<DialogPanelProps, "withPortal" | "open" | "contentMotionProps" | "overlayMotionProps">

/**
 *
 * Renders a Dialog (modal).
 * Children of this component are only rendered when the dialog is open. This is meant to be used for controlled dialogs only.
 *
 * @example
 * ```tsx
 * <Dialog>
 *  <DialogBody>This is some content</DialogBody>
 * </Dialog>
 * ```
 *
 * If you want to use an uncontrolled dialog (DialogTrigger), compose your Dialog using
 * the `DialogRoot` and `DialogPanel` components.
 *
 * @example
 * ```tsx
 * <DialogRoot>
 *  <DialogTrigger>Open Dialog</DialogTrigger>
 *  <DialogPanel>
 *   <DialogBody>This is some content</DialogBody>
 *  </DialogPanel>
 * </DialogRoot>
 *  ```
 *
 * @see https://web-docs.spatial.io/components/dialog
 */
const Dialog = ({
  children,

  // Dialog panel props
  showCloseButton,
  closeButtonClassName,
  withPortal,
  contentMotionProps,
  overlayMotionProps,

  // Rest of DialogRoot props
  open,
  ...rest
}: DialogProps) => {
  return (
    <DialogRoot open={open} {...rest}>
      <DialogPanel
        closeButtonClassName={closeButtonClassName}
        open={open}
        showCloseButton={showCloseButton}
        withPortal={withPortal}
        contentMotionProps={contentMotionProps}
        overlayMotionProps={overlayMotionProps}
      >
        {children}
      </DialogPanel>
    </DialogRoot>
  )
}
Dialog.displayName = "Dialog"

export type DialogRootProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Root> & {
  /**
   * A convenience method that is called when the dialog is closed. You should avoid passing both
   * `onRequestClose` and `onOpenChange`, as that may result in running 2 callbacks for a single event.
   */
  onRequestClose?: () => void
  /**
   * Whether the dialog should be closed when clicking on overlay. Defaults to `true`.
   */
  shouldCloseOnOverlayClick?: boolean
  withPortal?: boolean
} & VariantProps<typeof dialogBodyVariants> &
  VariantProps<typeof dialogContentVariants>

const DialogRoot = ({
  size = "md",
  theme = "auto",
  shouldCloseOnOverlayClick = true,
  children,
  ...props
}: DialogRootProps) => {
  return (
    <DialogContext.Provider value={{ size, theme, shouldCloseOnOverlayClick }}>
      <DialogPrimitive.Root
        {...props}
        onOpenChange={(open) => {
          if (!open && props.onRequestClose) props?.onRequestClose()
          props.onOpenChange?.(open)
        }}
      >
        {children}
      </DialogPrimitive.Root>
    </DialogContext.Provider>
  )
}
DialogRoot.displayName = "DialogRoot"

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

export const dialogOverlayFramerProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2, ease: "easeInOut" },
}

export type DialogOverlayProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay> & {
  motionProps?: MotionProps
}

const DialogOverlay = forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, DialogOverlayProps>(
  ({ className, motionProps, ...props }, ref) => (
    <DialogPrimitive.Overlay asChild ref={ref} {...props}>
      <m.div
        className={cn("fixed inset-0 z-modal bg-black/80", className)}
        {...dialogOverlayFramerProps}
        {...motionProps}
      />
    </DialogPrimitive.Overlay>
  )
)
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

type DialogPanelProps = Omit<DialogContentProps, "motionProps"> & {
  withPortal?: boolean
  showOverlay?: boolean
  open: boolean
  contentMotionProps?: MotionProps
  overlayMotionProps?: MotionProps
}

const dialogContentVariants = cva("", {
  variants: {
    theme: {
      dark: "dark-theme",
      light: "light-theme",
      auto: "",
    },
  },
  defaultVariants: {
    theme: "auto",
  },
})

const DialogPanel = forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogPanelProps>((props, ref) => {
  const { theme, shouldCloseOnOverlayClick } = useContext(DialogContext)
  const {
    children,
    className,
    open,
    showOverlay = true,
    withPortal = true,
    contentMotionProps,
    overlayMotionProps,
    ...rest
  } = props

  const Comp = withPortal ? DialogPortal : Fragment

  return (
    <AnimatePresence>
      {open ? (
        <Comp forceMount>
          {showOverlay && <DialogOverlay motionProps={overlayMotionProps} />}
          <DialogContent
            ref={ref}
            className={cn(dialogContentVariants({ theme, className }))}
            motionProps={contentMotionProps}
            onCloseAutoFocus={(e) => {
              e.preventDefault()
            }}
            /**
             * Radix calls this function when a pointer event occurs outside the dialog.
             * Prevent the event so that the dialog doesn't close.
             * @see https://www.radix-ui.com/primitives/docs/components/dialog#content
             */
            onPointerDownOutside={shouldCloseOnOverlayClick ? undefined : (event) => event.preventDefault()}
            onInteractOutside={
              shouldCloseOnOverlayClick
                ? (event) => {
                    /**
                     * Don't dismiss dialog when clicking inside toast.
                     * This is needed for dialog (modal) that contains toast notifications inside it. e.g. content menu modal.
                     *
                     * To make dialogs work with toast, you need to set `modal={false}` in the Dialog component.
                     */
                    if ((event.target as Element)?.closest("[data-sonner-toast]")) {
                      event.preventDefault()
                    }
                  }
                : (event) => event.preventDefault()
            }
            {...rest}
          >
            {children}
          </DialogContent>
        </Comp>
      ) : null}
    </AnimatePresence>
  )
})
DialogPanel.displayName = "DialogPanel"

type DialogContentProps = React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
  showCloseButton?: boolean
  closeButtonClassName?: string
  motionProps?: MotionProps
}

export const dialogContentFramerProps = {
  initial: { opacity: 0, scale: 0.95, x: "-50%", y: "-48%" },
  animate: { opacity: 1, scale: 1, x: "-50%", y: "-50%" },
  exit: { opacity: 0, scale: 0.95, x: "-50%", y: "-48%" },
  transition: { duration: 0.2, ease: "easeInOut" },
}

const DialogContent = forwardRef<React.ElementRef<typeof DialogPrimitive.Content>, DialogContentProps>(
  ({ className, children, motionProps, showCloseButton = true, closeButtonClassName, ...props }, ref) => (
    <DialogPrimitive.Content asChild ref={ref} {...props}>
      <m.div
        className={cn(
          "fixed left-[50%] top-[50%] z-modal grid translate-x-[-50%] translate-y-[-50%] gap-4 rounded-2xl bg-modal shadow-lg",
          className
        )}
        {...dialogContentFramerProps}
        {...motionProps}
      >
        {children}
        {showCloseButton && (
          <DialogClose
            className={cn(
              "absolute right-2 top-2 flex h-8 w-8 items-center justify-center rounded-full bg-modal ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none",
              closeButtonClassName
            )}
          >
            <CloseIcon className="h-6 w-6 text-foreground" />
            <span className="sr-only">Close</span>
          </DialogClose>
        )}
      </m.div>
    </DialogPrimitive.Content>
  )
)
DialogContent.displayName = DialogPrimitive.Content.displayName

export type DialogBodyProps = React.HTMLAttributes<HTMLDivElement> & BaseComponentProps

const dialogBodyVariants = cva("grid max-h-[90vh] max-w-[100vw] grid-cols-1 p-dialog-body text-foreground", {
  variants: {
    size: {
      sm: "w-[400px] gap-4 [--dialog-body-padding:2rem]",
      md: "w-[600px] gap-6 [--dialog-body-padding:2.5rem]",
      lg: "w-[900px] gap-6 [--dialog-body-padding:3rem]",
    },
    defaultVariants: {
      size: "md",
    },
  },
})

const DialogBody = ({ className, asChild, ...props }: DialogBodyProps) => {
  const { size } = useContext(DialogContext)
  const Comp = asChild ? Slot : "div"
  return <Comp className={cn(dialogBodyVariants({ size, className }))} {...props} />
}

DialogBody.displayName = "DialogBody"

const DialogHeader = ({ className, asChild, ...props }: React.HTMLAttributes<HTMLDivElement> & BaseComponentProps) => {
  const Comp = asChild ? Slot : "div"
  return <Comp className={cn("flex flex-col space-y-2 text-center", className)} {...props} />
}
DialogHeader.displayName = "DialogHeader"

const dialogButtonsVariants = cva("grid grid-flow-col justify-center gap-3", {
  variants: {
    size: {
      sm: "grid-flow-row grid-cols-1",
      md: "auto-cols-fr gap-4",
      lg: "auto-cols-[260px] justify-self-center",
    },
  },
  defaultVariants: {
    size: "md",
  },
})

const DialogButtons = ({ className, asChild, ...props }: React.HTMLAttributes<HTMLDivElement> & BaseComponentProps) => {
  const { size } = useContext(DialogContext)
  const Comp = asChild ? Slot : "div"
  return <Comp className={cn(dialogButtonsVariants({ size, className }))} {...props} />
}
DialogButtons.displayName = "DialogButtons"

const DialogTitle = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title ref={ref} className={cn("z-10 font-heading text-2xl font-black", className)} {...props} />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm font-normal text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

export {
  Dialog,
  DialogRoot,
  DialogBody,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogContent,
  DialogPanel,
  DialogHeader,
  DialogButtons,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
}
