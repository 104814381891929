import clsx from "clsx"
import Link from "next/link"
import { MouseEventHandler, Suspense, lazy, memo, useCallback, useMemo, useRef } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Transition } from "react-transition-group"

import type { SpaceAndCreator, SpaceMetadata } from "@spatialsys/js/sapi/types"
import { TrackedComponentByMount, TrackedComponents, useTrackView } from "@spatialsys/react/analytics"
import { useBoolean } from "@spatialsys/react/hooks/use-boolean"
import { SpaceItemClickMetadata } from "@spatialsys/unity/bridge"
import { formatSpacePath } from "@spatialsys/url-utils"
import { CollapsableText } from "@spatialsys/web/core/js/components/collapsable-text/collapsable-text"
import { HorizontalScrollable } from "@spatialsys/web/core/js/components/horizontal-scrollable/horizontal-scrollable"
import { LinkOrButton } from "@spatialsys/web/core/js/components/link-or-button/link-or-button"
import { Tag } from "@spatialsys/web/core/js/components/tag/tag"
import { useMouseRelative } from "@spatialsys/web/core/js/hooks/use-mouse-relative"
import { logger } from "@spatialsys/web/logger"
import { Loader, cn } from "@spatialsys/web/ui"

import { spatialCdnImageLoader } from "../../../util/next-image-loader"
import { LoveCount } from "../../social-signals/love-count"
import { ViewCount } from "../../social-signals/view-count"
import { OverflowMenu } from "../overflow-menu/overflow-menu"
import { SpaceNameAndCreator } from "../space-name-and-creator/space-name-and-creator"
import { Thumbnail } from "../thumbnail"
import { ThumbnailOverlay } from "../thumbnail-overlay/thumbnail-overlay"

import classes from "./hover-preview.module.scss"

const CubemapPreview = lazy(() =>
  import(
    /* webpackChunkName: "cubemap-preview" */ "@spatialsys/web/core/js/components/cubemap-preview/cubemap-preview"
  ).then((module) => ({
    default: module.CubemapPreview,
  }))
)

type SpacesGridItemHoverPreviewProps = {
  hideOverflowMenu?: boolean
  isHovering: boolean
  spaceData: SpaceAndCreator
  openCreatorProfileInNewTab: boolean
  asButton: boolean
  handleCopy: () => void
  handleDeleteSpace: (space: SpaceMetadata) => void
  handleRenameSpace: (space: SpaceMetadata) => void
  /**
   * If true, renders a button in the overflow menu to "set as banner"
   */
  handleSetBanner?: (space: SpaceMetadata) => void
  onSelectSpace: (spaceData: SpaceAndCreator, metadata?: SpaceItemClickMetadata) => void
  isLoadingLoved: boolean
  handleLogin: () => void
  spaceListIndex: number
}

const LoadingSpinner = () => (
  <div className={classes.loaderContainer}>
    <Loader color="white" className={classes.loader} />
  </div>
)

const HoverPreviewCard = memo(function HoverPreviewCard({
  asButton,
  isHovering,
  hideOverflowMenu,
  handleCopy,
  handleDeleteSpace,
  handleLogin,
  handleRenameSpace,
  handleSetBanner,
  onSelectSpace,
  isLoadingLoved,
  mousePos,
  openCreatorProfileInNewTab,
  spaceData,
  spaceListIndex,
}: SpacesGridItemHoverPreviewProps & Pick<React.ComponentProps<typeof CubemapPreview>, "mousePos">) {
  const { space } = spaceData
  const [thumbnailHovered, setThumbnailHovered] = useBoolean(true)
  const [spaceNameHovered, setSpaceNameHovered] = useBoolean(false)
  const [cubemapPreviewLoaded, setCubemapPreviewLoaded] = useBoolean(false)

  const spacePath = formatSpacePath({ id: space.id, slug: space.slug, shareId: space.shareID })
  const hasCubemap = Boolean(space.roomThumbnails.cubemapGetUrl)

  const trackView = useTrackView()

  const properties = useMemo(
    () => ({
      "Room ID": space.id,
      "Space List Index": spaceListIndex,
    }),
    [space.id, spaceListIndex]
  )

  const handleCubemapPreviewLoaded = useCallback(() => {
    setCubemapPreviewLoaded.setTrue()
    trackView(TrackedComponents.CubemapPreviewMesh, properties)
  }, [properties, setCubemapPreviewLoaded, trackView])

  const cubemapSrc = useMemo(() => {
    if (!space.roomThumbnails.cubemapGetUrl) return ""
    return spatialCdnImageLoader({
      src: space.roomThumbnails.cubemapGetUrl,
      width: 1024,
      quality: 75,
    })
  }, [space.roomThumbnails.cubemapGetUrl])

  const videoSrc = useMemo(() => {
    if (!space.customVideo?.webmLowRes && !space.customVideo?.mp4) return undefined
    return (
      <>
        {space.customVideo?.webmLowRes && <source src={space.customVideo?.webmLowRes} type="video/webm" />}
        {space.customVideo?.mp4 && <source src={space.customVideo?.mp4} type="video/mp4" />}
      </>
    )
  }, [space.customVideo?.mp4, space.customVideo?.webmLowRes])

  const handlePreviewClick = useCallback(() => {
    let metadata: SpaceItemClickMetadata = {}
    if (videoSrc && thumbnailHovered) {
      metadata = { "On Video Thumbnail": true }
    } else if (cubemapPreviewLoaded && thumbnailHovered) {
      metadata = { "On Cubemap Preview": true }
    } else {
      metadata = { "On Space Thumbnail": true }
    }
    onSelectSpace(spaceData, metadata)
  }, [cubemapPreviewLoaded, onSelectSpace, spaceData, thumbnailHovered, videoSrc])

  const handleNameClick = useCallback(() => {
    onSelectSpace(spaceData, { "On Space Name": true })
  }, [onSelectSpace, spaceData])

  return (
    <TrackedComponentByMount id={TrackedComponents.SpaceListItemHoverPreviewCard} properties={properties}>
      <LinkOrButton
        className={clsx(classes.thumbnailContainer, classes.flatBottom)}
        asButton={asButton}
        linkHref={spacePath}
        onClick={handlePreviewClick}
        onMouseEnter={setThumbnailHovered.setTrue}
        onFocus={setThumbnailHovered.setTrue}
      >
        <Thumbnail thumbnailUrl={space.thumbnail} name={space.name} sharpCorners videoSrc={videoSrc} />

        {!videoSrc && hasCubemap && (
          <ErrorBoundary fallbackRender={() => null} onError={logger.error}>
            <div className={classes.cubemapPreviewContainer}>
              <Suspense fallback={<LoadingSpinner />}>
                <TrackedComponentByMount id={TrackedComponents.CubemapPreview} properties={properties}>
                  <CubemapPreview
                    className={clsx(classes.cubemapPreview, cubemapPreviewLoaded && classes.withFade)}
                    src={cubemapSrc}
                    isHovered={thumbnailHovered}
                    onLoaded={handleCubemapPreviewLoaded}
                    mousePos={mousePos}
                  />
                </TrackedComponentByMount>
              </Suspense>
            </div>
          </ErrorBoundary>
        )}
      </LinkOrButton>
      <div className={classes.thumbnailOverlay}>
        <ThumbnailOverlay
          handleUnauthenticatedClick={handleLogin}
          isLoadingLoved={isLoadingLoved}
          showLoveButton="always"
          space={space}
        />
      </div>
      <div
        className={clsx(classes.innerContainer, "bg-popover shadow-sm")}
        onMouseEnter={setThumbnailHovered.setFalse}
        onFocus={setThumbnailHovered.setFalse}
      >
        <div className={classes.thumbnailPlaceholder} />
        <div className="relative m-3 grid grid-cols-1 gap-4">
          <div className="grid grid-cols-[minmax(0,1fr)_auto] gap-3">
            <div className="w-full">
              <SpaceNameAndCreator
                spaceHovered={thumbnailHovered || spaceNameHovered}
                setSpaceHovered={setSpaceNameHovered}
                openCreatorProfileInNewTab={openCreatorProfileInNewTab}
                asButton={asButton}
                spaceAndCreator={spaceData}
                onClick={handleNameClick}
              />
              <div className="text-xs text-muted-foreground">
                <ViewCount numViews={space.joinCount} />
                <span className="px-0.5">•</span>
                <LoveCount numLoves={space.likeCount} />
              </div>
            </div>
            {!hideOverflowMenu && (
              <OverflowMenu
                forceClose={!isHovering}
                isVisible
                space={space}
                onCopyUrl={handleCopy}
                onDelete={() => handleDeleteSpace(space)}
                onRename={() => handleRenameSpace(space)}
                onSetAsBanner={handleSetBanner ? () => handleSetBanner?.(space) : undefined}
              />
            )}
          </div>
          {space.description && (
            <div className={classes.description}>
              <CollapsableText text={space.description} />
            </div>
          )}
          {space.tags?.length > 0 && (
            <HorizontalScrollable classNameArrow="bg-muted dark:bg-background hover:bg-gray-200 dark:hover:bg-accent">
              <div className="flex flex-nowrap space-x-1">
                {space.tags.map((tag) => (
                  <Link key={tag} href={{ pathname: `/categories/${tag}` }} className="no-underline">
                    <Tag
                      className="whitespace-nowrap hover:bg-gray-200 dark:bg-background dark:hover:bg-accent"
                      clickable
                    >
                      {tag}
                    </Tag>
                  </Link>
                ))}
              </div>
            </HorizontalScrollable>
          )}
        </div>
      </div>
    </TrackedComponentByMount>
  )
})

/**
 * Displays a preview of a space when the user hovers
 */
export const SpacesGridItemHoverPreview = memo(function SpacesGridItemHoverPreview(
  props: SpacesGridItemHoverPreviewProps & { onMouseEnter?: MouseEventHandler; onMouseLeave?: MouseEventHandler }
) {
  const { isHovering } = props
  const transitionNodeRef = useRef(null)
  const finalSizeContainerRef = useRef(null)

  const mousePos = useMouseRelative({ ref: finalSizeContainerRef, enabled: isHovering })

  return (
    <div className={cn(classes.container, "z-20", isHovering && "z-popover")}>
      <div className={classes.finalSizeContainer} ref={finalSizeContainerRef}>
        <div className={clsx(classes.scaleInContainer, isHovering && classes.visible)}>
          <Transition nodeRef={transitionNodeRef} in={isHovering} timeout={250} mountOnEnter unmountOnExit>
            <div ref={transitionNodeRef} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
              <HoverPreviewCard {...props} mousePos={mousePos} />
            </div>
          </Transition>
        </div>
      </div>
    </div>
  )
})
