import Image from "next/image"
import { memo } from "react"

import Config from "@spatialsys/web/config"
import { spatialCdnImageLoader } from "@spatialsys/web/core/js/util/next-image-loader"

const BRANDS = [
  {
    name: "Boss",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/boss.webp`,
  },
  {
    name: "Utah Jazz",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/utah-jazz.webp`,
  },
  {
    name: "Walmart",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/walmart.webp`,
  },
  {
    name: "Jack Daniels",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/jack-daniels.webp`,
  },
  {
    name: "BMW",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/bmw.webp`,
  },
  {
    name: "Vogue",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/vogue.webp`,
  },
  {
    name: "McDonalds",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/mcdonalds.webp`,
  },
  {
    name: "Hublot",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/hublot.webp`,
  },
  {
    name: "GameStop",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/game-stop.webp`,
  },
  {
    name: "Givenchy",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/givenchy.webp`,
  },
  {
    name: "Elle Magazine",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/elle-magazine.webp`,
  },
  {
    name: "Tommy Hilfiger",
    image: `${Config.PUBLIC_ASSETS_BASE_URL}/brands/brand-partnerships/tommy-hilfiger.webp`,
  },
]

export const Gallery = memo(function Gallery() {
  return (
    <div className="grid w-full grid-cols-3 justify-items-center gap-8 xs:gap-4 sm:gap-8 md:w-auto md:grid-cols-4">
      {BRANDS.map(({ name, image }) => (
        <Image height="80" width="80" alt={name} key={name} src={image} loader={spatialCdnImageLoader} unoptimized />
      ))}
    </div>
  )
})
