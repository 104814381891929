import { m } from "framer-motion"
import Link from "next/link"
import { memo, useCallback } from "react"

import { ReactComponent as EmailIcon } from "@spatialsys/assets/icons/material-outlined/mail.svg"
import { InteractionName } from "@spatialsys/react/analytics"
import { InteractionType } from "@spatialsys/react/analytics"
import { useTrackInteraction } from "@spatialsys/react/analytics"
import { useAppContext } from "@spatialsys/web/app-context"
import Config from "@spatialsys/web/config"
import { cn } from "@spatialsys/web/ui"
import { Button } from "@spatialsys/web/ui"

import { useLocale } from "../../homepage"
import { SectionHeader } from "../../layouts/marketing/section/section-header"

type VirtualExperienceProps = {
  isSmallScreen: boolean
}

export const VirtualExperience = memo(function VirtualExperience({ isSmallScreen }: VirtualExperienceProps) {
  const locale = useLocale()
  const isKorean = locale === "ko" || locale === "ko-KR"
  const trackInteraction = useTrackInteraction()
  const actions = useAppContext((context) => context.actions)
  const showOldHomepage = useCallback(() => {
    actions.showOldHomepage()
  }, [actions])

  return (
    <div className="relative flex h-[500px] flex-col justify-start overflow-hidden px-4 sm:justify-center sm:px-[120px] lg:h-[640px]">
      <div className="mt-12 flex flex-col sm:mt-0">
        <SectionHeader
          align="left"
          noPadding
          title={isKorean ? "지금 시작하세요" : "Let's Begin"}
          titleClassName={isKorean ? "font-kr font-bold" : undefined}
          catchphrase={
            isKorean ? (
              <>
                당신만의 가상 경험을
                <br />
                만들어 보세요
              </>
            ) : (
              <>
                Create Your
                <br />
                Virtual Experience
              </>
            )
          }
          catchphraseClassName={isKorean ? "font-kr" : undefined}
        />
        <div className="z-10 flex items-center gap-4 sm:gap-6">
          <Button
            size={isSmallScreen ? "lg" : "xl"}
            className={cn(
              "relative w-60 overflow-hidden bg-gradient-to-r from-[#00CCFF] to-[#0033FF] text-lg text-white shadow-none hover:scale-100 sm:!shadow-[0_4px_32px_4px_rgba(0,102,255,.6)]",
              isSmallScreen && "text-md w-[170px]",
              isKorean && "font-kr font-bold"
            )}
            onClick={showOldHomepage}
          >
            <span className="pointer-events-none z-10">{isKorean ? "시작하기" : "Get started"}</span>
            <div className="absolute right-0 top-0 size-full bg-[#0044ff] opacity-0 transition-opacity duration-500 hover:opacity-100" />
          </Button>
          <Button
            size={isSmallScreen ? "lg" : "xl"}
            variant="text"
            as={Link}
            href="https://spatialxr.typeform.com/enterprise"
            target="_blank"
            color="white"
            leftIcon={<EmailIcon className={cn("icon icon-md", isSmallScreen && "icon-sm")} />}
            className={cn("w-fit px-0 text-lg", isSmallScreen && "text-md", isKorean && "font-kr font-bold")}
            onClick={() => {
              trackInteraction({
                type: InteractionType.Click,
                name: InteractionName.HomepageHeaderContactSales,
              })
            }}
          >
            {isKorean ? "협업 문의" : "Contact Sales"}
          </Button>
        </div>
      </div>
      <m.img
        src={`${Config.PUBLIC_ASSETS_BASE_URL}/homepage/images/rebranding/virtual-experience/virtual-experience.webp`}
        alt="Virtual Experience"
        className="absolute bottom-0 right-0 z-0 w-full max-w-[680px] sm:w-[60%] sm:max-w-[1180px] lg:w-[70%]"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, margin: "0px 0px -180px 0px" }}
        transition={{ duration: 0.8 }}
      />
    </div>
  )
})
