import { AxiosInstance } from "axios"

import { createAccountEndpoint } from "./account"
import { createAvatarsEndpoint } from "./avatars"
import { createBadgesEndpoint } from "./badges"
import { createEmotesEndpoint } from "./emotes"
import { createPackagesEndpoint } from "./packages"
import { createPayoutsEndpoint } from "./payouts"
import { createPlacebSsoEndpoint, createSamlSsoEndpoint } from "./saml-sso"
import { createWalletEndpoint } from "./wallet"
import { createWorldsEndpoint } from "./worlds"

/**
 * SAPI is migrating to a new version of the users service. This route is under `<baseUrl>/v2/users`
 */
export function createUsersV2Endpoints(client: AxiosInstance) {
  return {
    avatars: createAvatarsEndpoint(client),
    badges: createBadgesEndpoint(client),
    packages: createPackagesEndpoint(client),
    payouts: createPayoutsEndpoint(client),
    worlds: createWorldsEndpoint(client),
    wallet: createWalletEndpoint(client),
    emotes: createEmotesEndpoint(client),
    samlSso: createSamlSsoEndpoint(client),
    placebSso: createPlacebSsoEndpoint(client),
    account: createAccountEndpoint(client),
  }
}
